<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export_partner_transaction')">
      <b-col sm>
        <b-col class="d-flex align-items-center justify-content-start p-0" sm>
          <b-button
            variant="success"
            @click="handleGenerate"
            class="my-2 mr-3"
            :disabled="isLoadingButton"
          >
            <div
              class="sk-three-bounce custom-loading-bounce"
              v-if="isLoadingButton"
            >
              <div class="sk-child sk-bounce1"></div>
              <div class="sk-child sk-bounce2"></div>
              <div class="sk-child sk-bounce3"></div>
            </div>
            <div v-else>
              Generate report
            </div>
          </b-button>
          <b-badge class="badge-info-generate" v-show="count > 0">
            <p class="badge-info-count">
              {{ count }}
            </p>
            <p>
              <strong>Report is being processed,</strong> check the Report
              Transaction menu periodically to download the file.
            </p>
          </b-badge>
        </b-col>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Type Transaction :"
            label-for="sp"
          >
            <b-form-select
              id="sp"
              v-model="type"
              :options="[
                { text: 'Search Transaction By...', value: '' },
                { text: 'Beli Emas', value: 1 },
                { text: 'Jual Emas', value: 2 },
                { text: 'Cetak Emas', value: 6 },
                { text: 'Transfer Emas', value: 9 }
              ]"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Search By Partner :" label-for="sp">
            <b-form-select
              id="sp"
              v-model="filterPartner"
              :options="partnerOptions"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, Phone, Invoice No, Referral Code..."
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, Phone, Invoice No, Referral Code..."
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                :disable="false"
                :autoUpdate="false"
                :timePicker="true"
                @picker="doDateFilter"
              >
              </range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <b-button
          :disabled="stateButton"
          :style="buttonStyle"
          class="mb-3"
          @click="doFilter"
        >
          Apply Filter
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isLoadingTable == true && data.length == 0">
      <b-skeleton-table
        :rows="10"
        :columns="12"
        :table-props="{ bordered: true }"
      ></b-skeleton-table>
    </div>
    <div v-else>
      <vuetable
        ref="vuetable"
        :api-mode="false"
        :fields="fields"
        :per-page="limit"
        :data-manager="dataManager"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
      >
        <template slot="name-slot" slot-scope="prop">
          <router-link
            :to="onAction('view-user', prop.rowData, prop.rowIndex)"
            target="_blank"
            >{{ prop.rowData.users.name }} ({{
              prop.rowData.users.email
            }})</router-link
          >
          <span></span>
        </template>
        <template slot="type-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_type }}</span>
        </template>
        <template slot="treasury_invoice-slot" slot-scope="prop">
          <span>{{ prop.rowData.invoice_number }}</span>
        </template>
        <template slot="partner_invoice-slot" slot-scope="prop">
          <span>{{ prop.rowData.partner_invoice }}</span>
        </template>
        <template slot="commission-slot" slot-scope="prop">
          <span>{{ prop.rowData.commission }} </span>
        </template>
        <template slot="gold_amount-slot" slot-scope="prop">
          <span>{{ prop.rowData.gold_amount }} </span>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>{{ prop.rowData.amount }} </span>
        </template>
        <template slot="status-slot" slot-scope="prop">
          <span>{{ prop.rowData.status }} </span>
        </template>
        <template slot="buying_rate-slot" slot-scope="prop">
          <span>{{ prop.rowData.buying_rate }} </span>
        </template>
        <template slot="selling_rate-slot" slot-scope="prop">
          <span>{{ prop.rowData.selling_rate }} </span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions" v-if="$can('detail_partner_transaction')">
            <button
              class="btn btn-success"
              @click="onAction('detail-item', prop.rowData)"
            >
              Detail
            </button>
          </div>
        </template>
      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <div class="wrapper-pagination-custom">
          <div class="pagination-custom-info">
            <p>{{ this.paginationInfo }}</p>
          </div>
          <div class="pagination-custom-button">
            <button :disabled="currentPage === 1" @click="doMovePage('first')">
              <i
                v-if="currentPage === 1"
                class="fa fa-angle-double-left disabled"
              ></i>
              <i v-else class="fa fa-angle-double-left"></i>
            </button>
            <button :disabled="currentPage === 1" @click="doMovePage('prev')">
              <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
              <i v-else class="fa fa-angle-left"></i>
            </button>
            <div>{{ this.currentPage }}</div>
            <button :disabled="data.length < 10" @click="doMovePage('next')">
              <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
              <i v-else class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import _ from "lodash";
import moment from "moment";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      count: 0,
      page: 1,
      currentPage: 1,
      paginationInfo: "",
      limit: 10,
      data: [],
      startDate: "",
      endDate: "",
      type: "",
      filterText: "",
      filterPartner: "",
      isLoading: false,
      isLoadingTable: false,
      isLoadingButton: false,
      cryptoOption: "",
      errors: {
        code: "",
        message: "",
        status: ""
      },
      typeOptions: [
        { value: "", text: "All" },
        { value: "beli_asset", text: "Beli Asset" },
        { value: "jual_asset", text: "Jual Asset" },
        { value: "top_up", text: "Top Up" },
        { value: "withdraw", text: "Withdraw" }
      ],
      partnerOptions: [{ value: "", text: "Select Partner" }],
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `partner-transaction`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "partner_gold_transaction.created_at|desc",
      sortOrder: [
        {
          field: "partner_gold_transaction.created_at",
          sortField: "partner_gold_transaction.created_at",
          direction: "desc"
        },
        {
          field: "name-slot",
          sortField: "partner_gold_transaction.partner_name",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "partner_gold_transaction.created_at",
          title: "Date"
        },
        {
          name: "name-slot",
          sortField: "partner_gold_transaction.partner_name",
          title: "User"
        },
        {
          name: "type-slot",
          title: "Transaction Type"
        },
        {
          name: "partner_name",
          title: "Partner"
        },
        {
          name: "treasury_invoice-slot",
          title: "Treasury Invoice No."
        },
        {
          name: "partner_invoice-slot",
          title: "Partner Invoice No."
        },
        {
          name: "gold_amount-slot",
          title: "Gold Amount"
        },
        {
          name: "amount-slot",
          title: "IDR Amount"
        },
        {
          name: "commission-slot",
          title: "Commission"
        },
        {
          name: "status-slot",
          title: "Transaction Status"
        },
        {
          name: "buying_rate-slot",
          title: "Gold Buy Pricee"
        },
        {
          name: "selling_rate-slot",
          title: "Gold Sell Price"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      paginationData: {
        current_page: 1,
        from: 1,
        last_page: 20,
        first_page_url:
          "http://development-183113.api.treasury.id/admin/v1/partner-transaction?sort=partner_gold_transaction.created_at%7Cdesc&page=1&per_page=10",
        next_page_url:
          "http://development-183113.api.treasury.id/admin/v1/partner-transaction?sort=partner_gold_transaction.created_at%7Cdesc&page=2&per_page=10",
        prev_page_url:
          "http://development-183113.api.treasury.id/admin/v1/partner-transaction?sort=partner_gold_transaction.created_at%7Cdesc&page=1&per_page=10",
        path: null,
        per_page: 10,
        to: 10,
        total: 1000
      }
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  computed: {
    stateButton() {
      const filters =
        this.type === "" &&
        this.filterPartner === "" &&
        this.filterText === "" &&
        this.startDate === "" &&
        this.endDate === "";
      const params =
        Object.entries(this.moreParams).filter(([key, value]) => value)
          .length == 0;

      return filters && params;
    },
    buttonStyle() {
      return {
        background: !this.stateButton ? "#7565F6" : "#c8ced3",
        color: !this.stateButton ? "#FFFFFF" : "inherit"
      };
    }
  },
  created() {
    this.handleSetCurrentDate();
    this.$http.get(`partner-list`).then(response => {
      const newPartner = response.data.map(item => {
        return { value: item.id, text: item.name };
      });
      this.partnerOptions = [...this.partnerOptions, ...newPartner];
    });
    this.onFilterSet();
  },
  methods: {
    onFetchNotification() {
      this.$http
        .get("report-transaction/on-process-count/partner")
        .then(response => {
          const countProgress = response.data.data.on_process_count;
          this.count = countProgress;

          if (countProgress > 0) {
            setTimeout(() => {
              this.count = 0;
            }, 60000);
          }
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    onFetch() {
      this.isLoadingTable = true;
      const newParams = Object.entries(this.moreParams)
        .filter(([value]) => value !== "")
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      this.$http
        .get(
          `partner-transaction?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=10&${newParams}`
        )
        .then(response => {
          const datas = response.data.data;
          const datasCount = datas.length;
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = Math.min(
            startIndex + this.limit,
            datasCount > 0 ? datasCount + startIndex : this.limit
          );

          this.data = datas;
          this.currentPage = response.data.current_page;
          this.paginationInfo =
            datasCount > 0
              ? `Displaying ${startIndex + 1} to ${endIndex}`
              : "No relevant data";

          this.isLoadingTable = false;
        })
        .catch(err => {
          this.isLoadingTable = false;
          this.handleLoadError(err);
        });
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    onAction(action, data) {
      if (action == "view-user") {
        return { name: "Detail End User", params: { id: btoa(data.users.id) } };
      } else if (action == "detail-item") {
        const routeData = this.$router.resolve({
          name: "Detail Partner Transaction",
          params: { id: btoa(data.id) }
        });
        window.open(routeData.href, "_blank");
      }
    },
    npwpVal(value) {
      return value != null ? value : "-";
    },
    doFilter() {
      this.$events.$emit("apply-filter");
    },
    resetFilter() {
      this.filterText = "";
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD HH:mm");
        startDate = this.$setDate
          .subtract(6, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm");
      }

      const start = moment(startDate).format("YYYY-MM-DD HH:mm");
      const end = moment(endDate).format("YYYY-MM-DD HH:mm");

      this.$http
        .get(
          `partner-transactions?startDate=${start}&endDate=${end}&filter=${encodeURIComponent(
            this.filterText
          )}&type=${this.type}&partner=${this.filterPartner}`
        )
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.status;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.handleSetCurrentDate();
    },
    allcap(value) {
      return value.toUpperCase();
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      console.log("paginationData", paginationData);
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    async onFilterSet() {
      this.page = 1;
      this.moreParams = {
        type: this.type,
        partner: this.filterPartner,
        filter: encodeURIComponent(this.filterText),
        start_from: this.startDate,
        end_to: this.endDate
      };
      this.onFetch();
    },
    doDateFilter(value) {
      this.startDate = moment(value.startDate).format("YYYY-MM-DD HH:mm");
      this.endDate = moment(value.endDate).format("YYYY-MM-DD HH:mm");
    },
    async onFilterReset() {
      this.page = 1;
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      this.onFetch();
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else {
        this.page++;
        this.onFetch();
      }
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    handleSetCurrentDate() {
      const today = new Date().toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      this.startDate = `${today} 00:00`;
      this.endDate = `${today} 23:59`;
    },
    handleGenerate() {
      this.isLoadingButton = true;

      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => value)
        .map(
          ([key, value]) =>
            `${
              key == "start_from"
                ? "startDate"
                : key == "end_to"
                ? "endDate"
                : key
            }=${value}`
        )
        .join("&");

      this.$http
        .get(`partner-transactions?${newParams}`)
        .then(result => {
          const countProgress = result.data.meta.data.on_process_count;

          this.count = countProgress;

          if (countProgress > 0) {
            setTimeout(() => {
              this.count = 0;
            }, 60000);
          }

          this.isLoadingButton = false;
        })
        .catch(error => {
          const err = error.response;
          if (err) {
            const code = err.data.code;
            const message = err.data.message;
            const status = err.data.status;

            this.isLoadingButton = false;
            this.$swal.fire(status, message, "error");
          }
        });
    }
  },
  mounted() {
    this.onFetchNotification();
    this.$events.$on("apply-filter", () => this.onFilterSet());
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));
  }
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-type,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-filter {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto;
}

.badge-info-generate {
  height: 32px;
  padding: 8px 12px;
  background-color: #fff3d3;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-info-count {
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0;
  background-color: #ffce50;
  border-radius: 100px;
}

.custom-loading-bounce {
  width: 72px !important;
  height: 18px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.custom-loading-bounce .sk-child {
  width: 6px !important;
  height: 6px !important;
  background: #ffffff !important;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
